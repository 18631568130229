import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

const { Subheading2 } = fonts

const StudyComplete: React.FunctionComponent = () => {
  const { t } = useTranslation('index')
  return (
    <Content>
      <Subheading2>{t('research.studyComplete')}</Subheading2>
    </Content>
  )
}

const Content = styled.div`
  padding-bottom: 5%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
  }
`

export default StudyComplete
