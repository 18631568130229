import React, { useState } from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

import { GameProgressDictionary } from '~/components/GameCardList/GameCardList'
import GameGroupList, { GameListType } from '~/components/GameCardList/GameGroupList'
import QueryLoader from '~/components/QueryLoader'
import { allGameSlugs } from '~/gameConfigs/GamesLibrary'
import { GameEngine, useGetGameProgressQuery } from '~/graphql/generated/schema'
import { groupByArea } from '~/utils/gameListUtils'

const { Heading1 } = fonts

export const ResearchGamesView = () => {
  const { t } = useTranslation('index')
  const [gamesByArea, setGamesByArea] = useState<GameProgressDictionary>({})

  const { loading, error, data } = useGetGameProgressQuery({
    variables: { slugs: allGameSlugs, engine: GameEngine.Cocos_3 },
    onCompleted: (data) => {
      setGamesByArea(groupByArea(data.me.gamesProgress))
    },
    // when gameOutput is submitted post-gamePlay, gameProgress for that game is updated,
    // but not for all games (apollo refetches the query with the most recent variables, which is the single game slug)
    // This fetch policy ensures the list is up to date when user clicks `Back to Games`
    // Optimistic cache update via addGameOutput mutation could potentially make this redundant
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      <Header as='h1'>{t('research.playMoreGames')}</Header>
      <QueryLoader loading={loading} error={error} data={data}>
        <GamesViewContainer>
          <ListContainer>
            <GameGroupList gamesList={gamesByArea} listType={GameListType.byArea} />
          </ListContainer>
        </GamesViewContainer>
      </QueryLoader>
    </>
  )
}

export default ResearchGamesView

const GamesViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const ListContainer = styled.div`
  position: relative;
  display: grid;
`

const Header = styled(Heading1)`
  padding: 20px 0 20px;
`
