import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

import ButtonLink from '~/components/ButtonLink'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import WorkoutDonut from '~/components/workout/WorkoutDonut'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import ChevronRight from '~/images/chevron_right.svg'
import GameGroupIcon from '~/images/Workout/GameGroupIcon.svg'
import { getTotalGamesCount, getTotalGamesPlayed } from '~/utils/workoutUtils'

const { Body3, Body3Caps, Heading3 } = fonts

export interface WorkoutCardProps {
  workout: GetTodaysWorkoutQuery
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ workout }) => {
  const { t } = useTranslation('index')

  const totalGameCount = getTotalGamesCount(workout)

  const totalGamesPlayed = getTotalGamesPlayed(workout)

  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const remainingGameCount = totalGameCount - totalGamesPlayed < 0 ? 0 : totalGameCount - totalGamesPlayed

  const { todaysWorkout } = workout
  const nextGame = todaysWorkout.workoutGames.find((workoutGame) => workoutGame.lastGamePlay === null)?.game?.slug
  const gameUrl = nextGame ? `/game/${nextGame}/workout` : '/'

  const workoutNotStarted = remainingGameCount === totalGameCount
  const CTA = workoutNotStarted ? 'begin' : 'continue'

  const WorkoutCardContent = () => {
    if (remainingGameCount === 0) {
      const headingText = t('workoutCard.headings.today.workoutComplete')
      const subheadingText = t('workoutCard.subheadings.today.workoutComplete')

      return (
        <Content>
          <Heading3>{headingText}</Heading3>
          <Body3>{subheadingText}</Body3>
        </Content>
      )
    } else {
      const headingText = t('workoutCard.headings.today.workoutIncomplete')

      const subheadingText = () => {
        if (workoutNotStarted) {
          return t('workoutCard.subheadings.today.gamesRemaining.initial', { gameCount: remainingGameCount })
        } else if (remainingGameCount === 1) {
          return t('workoutCard.subheadings.today.gamesRemaining.singular', { gameCount: remainingGameCount })
        } else {
          return t('workoutCard.subheadings.today.gamesRemaining.plural', { gameCount: remainingGameCount })
        }
      }

      const buttonText = t(`common:buttons.${CTA}`)

      return (
        <Content>
          <Heading3 style={{ width: 'max-content' }}>{headingText}</Heading3>
          <Details>
            <GameGroupIcon title='game_group_icon' />
            <Body3Caps>{subheadingText()}</Body3Caps>
          </Details>
          <CTAButton>
            <ButtonLink href={gameUrl} wide={true} rightBadge={<ChevronRight title={CTA} height='12px' />}>
              {buttonText}
            </ButtonLink>
          </CTAButton>
        </Content>
      )
    }
  }

  return (
    <WorkoutCardContainer>
      <WorkoutDonutContainer>
        <WorkoutDonut workout={workout} />
      </WorkoutDonutContainer>
      <WorkoutCardContent />
    </WorkoutCardContainer>
  )
}

export default WorkoutCard

const CTAButton = styled.div`
  padding-top: 20px;
  width: 80%;
  min-width: 230px;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    padding-top: 12px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    padding-top: 12px;
  }
`

const Content = styled.div`
  padding: 5%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  color: ${({ theme }) => theme.colors.inkBase};
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: 20px 50px 30px 50px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: 20px 50px 30px 50px;
  }
`

const Details = styled.div`
  color: ${({ theme }) => theme.colors.coolGray44};
  display: flex;
  gap: 8px;
`

const WorkoutCardContainer = styled(CardShadowRadius)`
  position: relative;
  max-height: 228px;
  display: flex;
  flex-direction: row;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    max-height: 324px;
    height: 100%;
    flex-direction: column;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    max-height: 324px;
    height: 100%;
    flex-direction: column;
  }
`

const WorkoutDonutContainer = styled.div`
  width: 33%;
  background-image: url('/assets/images/workout/workout-card-bg-color-burn.png');
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  background-position: 30% 75%;
  background-size: 235%;
  display: flex;
  justify-content: center;
  padding: 3%;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    width: 100%;
    height: 45%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    background-position: 15% 30%;
    background-size: 105%;
    padding: 10px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
    height: 45%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    background-position: 15% 30%;
    background-size: 105%;
    padding: 10px;
  }
`
