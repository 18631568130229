import React, { useContext } from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import styled, { ThemeContext } from 'styled-components'

import ProgressBar from '~/components/ui/ProgressBar'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import { GetScriptedWorkoutHistoryQuery } from '~/graphql/generated/schema'

const { Subheading2Bold, MicroText1BoldCaps } = fonts

interface ScriptedWorkoutStatsCardProps {
  scriptedWorkoutHistoryData: GetScriptedWorkoutHistoryQuery
}

const ScriptedWorkoutStatsCard: React.FC<ScriptedWorkoutStatsCardProps> = ({ scriptedWorkoutHistoryData }) => {
  const { t } = useTranslation('index')
  const themeContext = useContext(ThemeContext)
  const progressBarColor = themeContext.colors.ink30
  const backgroundColor = themeContext.colors.coolGray92

  const totalWorkouts = scriptedWorkoutHistoryData.scriptedWorkoutHistory.totalScriptedWorkouts
  const totalWorkoutsBegun = scriptedWorkoutHistoryData.scriptedWorkoutHistory.totalScriptedWorkoutsBegun
  const totalWorkoutsRemaining = totalWorkouts - totalWorkoutsBegun
  const progressPercentage = (totalWorkoutsBegun / totalWorkouts) * 100

  // get singular or plural workouts remaining string
  const workoutString = (count: number) => {
    const key = count === 1 ? 'workout' : 'workouts'
    return t(`research.${key}`, { count: count })
  }

  const isLastDay = totalWorkoutsRemaining <= 0
  const workoutRemainingText = isLastDay
    ? t('research.almostDone')
    : `${totalWorkoutsRemaining} ${workoutString(totalWorkoutsRemaining)}`

  const outOfTotalText = isLastDay
    ? t('research.finishToday')
    : t('research.outOfTotal', {
        totalWorkouts,
      })

  return (
    <CardContainer>
      <CardTitle>{t('research.workoutStatsHeader')}</CardTitle>
      <StatsContainer>
        <Subheading2Bold>{workoutRemainingText}</Subheading2Bold>
        <Subheading2Bold>{outOfTotalText}</Subheading2Bold>
        {!isLastDay && (
          <ProgressBarContainer>
            <ProgressBar
              progress={progressPercentage}
              color={progressBarColor}
              height={8}
              withBackground={true}
              backgroundColor={backgroundColor}
            />
          </ProgressBarContainer>
        )}
      </StatsContainer>
    </CardContainer>
  )
}

const CardContainer = styled(CardShadowRadius)`
  width: 326px;
  height: 200px;
`

const CardTitle = styled(MicroText1BoldCaps)`
  color: ${({ theme }) => theme.colors.coolGray62};
  padding: 18px 18px 8px;
  display: block;
`

const StatsContainer = styled.div`
  margin: 22px 10px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1fr;
  text-align: center;
  align-items: center;
`

const ProgressBarContainer = styled.div`
  width: 260px;
  margin: 20px;
`

export default ScriptedWorkoutStatsCard
