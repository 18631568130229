import { useReactiveVar } from '@apollo/client'
import { fonts } from '@lumoslabs/lumosity-storybook'
import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'
import Head from 'next/head'
import styled from 'styled-components'

import Footer from '~/components/Footer'
import MobileDevicesInterstitial from '~/components/MobileDevicesInterstitial'
import PremiumBanner from '~/components/PremiumBanner'
import QueryLoader from '~/components/QueryLoader'
import ResearchGamesView from '~/components/ResearchOnly/ResearchGamesView'
import ScriptedWorkoutStatsCard from '~/components/ResearchOnly/ScriptedWorkoutStatsCard'
import StudyComplete from '~/components/ResearchOnly/StudyComplete'
import StreakCard from '~/components/streaks/StreakCard'
import TopNav from '~/components/TopNav'
import PageContent from '~/components/ui/PageContent'
import WorkoutCard from '~/components/ui/WorkoutCard'
import { playableGameSlugs } from '~/gameConfigs/GamesLibrary'
import { useGetTodaysWorkoutQuery, useGetScriptedWorkoutHistoryQuery } from '~/graphql/generated/schema'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import useTrackScreenView from '~/hooks/analytics/useTrackScreenView'
import useIsMobileDevice from '~/hooks/useIsMobileDevice'
import useLocalDate from '~/hooks/useLocalDate'
import useScrollToTop from '~/hooks/useScrollToTop'
import { withApollo } from '~/lib/withApollo'

const { Heading1, Subheading2 } = fonts

const { publicRuntimeConfig } = getConfig()

interface HomeProps {
  name?: string
  date: Date
}

const Home: NextPage<HomeProps> = () => {
  const isResearch = publicRuntimeConfig.researchMode

  useTrackScreenView('home', 'side_nav')
  const { t } = useTranslation('index')
  const currentUser = useReactiveVar(currentUserVar)
  const name = currentUser?.firstName ?? ''
  const { dateFormatted, greetingKey } = useLocalDate()
  const isMobileDevice = useIsMobileDevice()
  useScrollToTop()

  const { data, loading, error } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: playableGameSlugs },
    fetchPolicy: 'cache-and-network',
    // see https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',
  })

  const {
    data: scriptedWorkoutHistoryData,
    loading: historyLoading,
    error: historyError,
  } = useGetScriptedWorkoutHistoryQuery({
    fetchPolicy: 'cache-and-network',
    // see https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',
  })

  const showStudyComplete = scriptedWorkoutHistoryData?.scriptedWorkoutHistory.studyComplete

  if (isMobileDevice) {
    return <MobileDevicesInterstitial />
  }

  const greeting = name ? t(`greetingWithName.${greetingKey()}`, { name: name }) : t(`greeting.${greetingKey()}`)

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <TopNav />
      <PremiumBanner />
      <PageContent>
        <PageContent.Header>
          <Subheading2>{dateFormatted}</Subheading2>
          <Heading1 as='h1'>{greeting}</Heading1>
        </PageContent.Header>
        <PageContent.Main>
          {showStudyComplete ? (
            <QueryLoader data={scriptedWorkoutHistoryData} loading={historyLoading} error={historyError}>
              <StudyComplete />
            </QueryLoader>
          ) : (
            <QueryLoader data={data} loading={loading} error={error}>
              {data && <WorkoutCard workout={data} />}
              <MobileStudyProgressContainer>
                {isResearch && !showStudyComplete && scriptedWorkoutHistoryData && (
                  <ScriptedWorkoutStatsCard scriptedWorkoutHistoryData={scriptedWorkoutHistoryData} />
                )}
              </MobileStudyProgressContainer>
            </QueryLoader>
          )}
          <ResearchGamesView />
        </PageContent.Main>
        <PageContent.Sidebar>
          {isResearch && !showStudyComplete
            ? scriptedWorkoutHistoryData && (
                <ScriptedWorkoutStatsCard scriptedWorkoutHistoryData={scriptedWorkoutHistoryData} />
              )
            : !isResearch && <StreakCard />}
        </PageContent.Sidebar>
      </PageContent>
      {isResearch && <Footer />}
    </>
  )
}

export default withApollo({ ssr: true })(Home)

export const MobileStudyProgressContainer = styled.div`
  margin: 40px 0;
  ${({ theme }) => theme.mediaQuery.minWidth.tabletMedium} {
    display: none;
  }
`
